/* eslint-disable no-console */
import axios from 'axios';
import Model from '@/Models/Model.js';
import StartList from '@/Models/StartList.js';
import Video from '@/Models/Video.js';

export default class UploadVideo extends Model {
    static entity = 'upload-videos';

    static primaryKey = '$id';

    static fields() {
        return {
            start_list_id: this.attr(null),
            num: this.number(1),
            file_name: this.string(''),
            video_filename: this.string(''),
            duration: this.attr(null),
            event: this.attr(null),
            round: this.attr(null),
            format: this.attr(null),
            progress: this.number(0),
            thumbnail: this.attr(null),
            uploading: this.boolean(false),
            mime_type: this.attr(null),
            uploaded: this.boolean(false),

            start_list: this.belongsTo(StartList, 'start_list_id'),
        };
    }

    get routine_type() {
        return {
            TI: 'App\\Models\\TrampolineScore',
            DM: 'App\\Models\\DoubleMiniScore',
            TU: 'App\\Models\\TumblingScore',
            TS: 'App\\Models\\SynchroScore',
        }[this.event];
    }

    get name() {
        return `${this.start_list?.competitor} - ${this.round} - ${this.num}`;
    }

    get full_file_name() {
        return `${this.file_name}.${this.format}`;
    }

    async generatePresignedUrl() {
        try {
            console.log({ fileName: this.full_file_name, mimeType: this.mime_type });

            const { data } = await axios.post('/api/generate-presigned-url', {
                fileName: this.full_file_name,
                mimeType: this.mime_type,
            });

            console.log(data);

            return data.presignedUrl.url;
        } catch (error) {
            console.error('Error generating pre-signed URL:', error);
            throw error;
        }
    }

    async upload(file, providedPresignedUrl) {
        let presignedUrl = providedPresignedUrl;

        if (!providedPresignedUrl) {
            presignedUrl = await this.generatePresignedUrl();
        }

        try {
            const uploadResponse = await axios.put(presignedUrl, file, {
                withCredentials: false,
                headers: {
                    'Content-Type': file.type,
                },
                onUploadProgress: progressEvent => {
                    this.$update({ progress: Math.round((progressEvent.loaded / progressEvent.total) * 100) });
                },
            });

            console.log('Video uploaded successfully:', uploadResponse);

            const videoResponse = await this.submitVideo();

            console.log('Created Scorsync video model', videoResponse);
        } catch (error) {
            console.error('Error uploading video:', error);
            throw error;
        }
    }

    async captureThumbnail(imageCapture) {
        const blob = await imageCapture.takePhoto();

        const url = URL.createObjectURL(blob);

        return this.$update({ thumbnail: url });
    }

    submitVideo() {
        const payload = {
            user_id: 1,
            identifier: this.file_name,
            title: 'Video',
            description: 'Video',
            num: this.num,
            routine_type: this.routine_type,
            video_filename: this.full_file_name,
        };

        return Video.api().post(`/api/start-lists/${this.start_list_id}/videos`, payload);
    }

    static download(file) {
        const url = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }
}
