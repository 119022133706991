import moment from 'moment-timezone';
import Model from '@/Models/Model.js';
import Club from '@/Models/Club.js';
import Result from '@/Models/Result.js';
import Video from '@/Models/Video.js';
import { avatarColor, level } from '@/helpers.js';

export default class Athlete extends Model {
    static entity = 'athletes';

    constructor(record) {
        super(record);

        this.follow_entity = 'athlete';
    }

    static fields() {
        return {
            id: this.attr(null),
            club_id: this.attr(null),
            usag_number: this.attr(null),
            first_name: this.string(''),
            last_name: this.string(''),
            gender: this.attr(null),
            birthdate: this.attr(null),
            is_birthday: this.boolean(false),
            image_url: this.attr(null),
            age_group: this.attr(null),
            profile_photo_path: this.attr(null),
            profile_photo_url: this.attr(null),
            is_sr_national_team_tra: this.boolean(false),
            is_sr_national_team_dmt: this.boolean(false),
            is_sr_national_team_tum: this.boolean(false),
            is_jr_national_team_tra: this.boolean(false),
            is_jr_national_team_dmt: this.boolean(false),
            is_jr_national_team_tum: this.boolean(false),
            is_edp_team_tra: this.boolean(false),
            is_edp_team_dmt: this.boolean(false),
            is_edp_team_tum: this.boolean(false),
            current_residence: this.attr(null),
            hometown: this.attr(null),
            instagram: this.attr(null),
            facebook: this.attr(null),
            tiktok: this.attr(null),
            twitter: this.attr(null),
            bio: this.attr(null),
            inactive_at: this.attr(null),
            age: this.attr(null),
            last_competed_at: this.attr(null),
            hidden_from_roster_at: this.attr(null),

            // Conditionally loaded
            tra_level: this.attr(null),
            dmt_level: this.attr(null),
            tum_level: this.attr(null),

            ...super.fields(),

            created_at: this.string(null),
            updated_at: this.string(null),
            deleted_at: this.attr(null),

            club: this.belongsTo(Club, 'club_id'),
            results: this.hasMany(Result, 'athlete_id'),
            videos: this.hasMany(Video, 'athlete_id'),
        };
    }

    get avatar_color() {
        return avatarColor(this.first_name, this.last_name);
    }

    get has_bio() {
        return this.current_residence || this.hometown || this.has_social || this.bio;
    }

    get has_social() {
        return this.instagram || this.facebook || this.tiktok;
    }

    get name() {
        return `${this.first_name} ${this.last_name}`;
    }

    get link() {
        return `/athletes/${this.id}`;
    }

    get initials() {
        return this.first_name.charAt(0) + this.last_name.charAt(0);
    }

    get recently_competed() {
        return this.last_competed_at && moment(this.last_competed_at).isAfter(moment().subtract(1, 'year'));
    }

    get tra_level_key() {
        return level(this.tra_level)?.order;
    }

    get dmt_level_key() {
        return level(this.dmt_level)?.order;
    }

    get tum_level_key() {
        return level(this.tum_level)?.order;
    }
}
