import Model from '@/Models/Model.js';
import Meet from '@/Models/Meet.js';

export default class Announcement extends Model {
    static entity = 'announcements';

    static modelName = 'Announcement';

    static fields() {
        return {
            id: this.attr(null),
            meet_id: this.attr(null),

            title: this.string(null),
            description: this.string(null),
            sitewide: this.boolean(false),
            visible_from: this.attr(null),
            visible_to: this.attr(null),
            notify_at: this.attr(null),
            notified_at: this.attr(null),

            created_at: this.string(null),
            updated_at: this.string(null),
            created_by: this.attr(null),
            updated_by: this.attr(null),

            ...super.fields(),

            meet: this.belongsTo(Meet, 'meet_id'),
        };
    }
}
