import Model from '@/Models/Model.js';

export default class DoubleMiniSkill extends Model {
    static entity = 'double-mini-skills';

    static fields() {
        return {
            id: this.attr(null),
            table: this.number(0),
            num: this.number(0),
            direction: this.string(''),
            skill: this.string(''),
            value: this.string(''),
        };
    }
}
