import Model from '@/Models/Model.js';
import Meet from '@/Models/Meet.js';
import TeamQualifier from '@/Models/TeamQualifier.js';

export default class MeetTeamQualifier extends Model {
    static entity = 'meet-team-qualifiers';

    static primaryKey = ['meet_id', 'team_qualifier_id'];

    static fields() {
        return {
            id: this.attr(null),
            meet_id: this.attr(null),
            team_qualifier_id: this.attr(null),
            created_at: this.string(null),
            updated_at: this.string(null),

            meet: this.belongsTo(Meet, 'meet_id'),
            team_qualifier: this.belongsTo(TeamQualifier, 'team_qualifier_id'),
        };
    }
}
