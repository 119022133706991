import moment from 'moment-timezone';
import Model from '@/Models/Model.js';
import Meet from '@/Models/Meet.js';
import Flight from '@/Models/Flight.js';
import StartListModel from '@/Models/StartList.js';

export default class Session extends Model {
    static entity = 'sessions';

    static fields() {
        return {
            id: this.attr(null),
            meet_id: this.attr(null),
            title: this.attr(null),
            order: this.attr(null),
            original_title: this.attr(null),
            starts_at: this.attr(null),
            ends_at: this.attr(null),
            ordinal: this.attr(null),
            equipment: this.attr(null),

            open_warmup_starts_at: this.attr(null),
            open_warmup_ends_at: this.attr(null),
            timed_warmup_starts_at: this.attr(null),
            timed_warmup_ends_at: this.attr(null),
            march_in_starts_at: this.attr(null),
            march_in_ends_at: this.attr(null),
            awards_starts_at: this.attr(null),

            trampoline_flights_count: this.number(0),
            double_mini_flights_count: this.number(0),
            tumbling_flights_count: this.number(0),
            synchro_flights_count: this.number(0),

            ...super.fields(),

            meet: this.belongsTo(Meet, 'meet_id'),
            flights: this.hasMany(Flight, 'session_id'),
        };
    }

    get has_trampoline() {
        return this.flights.some(flight => flight.event === 'TI');
    }

    get has_double_mini() {
        return this.flights.some(flight => flight.event === 'DM');
    }

    get has_tumbling() {
        return this.flights.some(flight => flight.event === 'TU');
    }

    get has_synchro() {
        return this.flights.some(flight => flight.event === 'TS');
    }

    get trampoline_flights() {
        return this.flights.filter(flight => flight.event === 'TI');
    }

    get double_mini_flights() {
        return this.flights.filter(flight => flight.event === 'DM');
    }

    get tumbling_flights() {
        return this.flights.filter(flight => flight.event === 'TU');
    }

    get synchro_flights() {
        return this.flights.filter(flight => flight.event === 'TS');
    }

    get title_with_time() {
        if (!this.meet) {
            return this.title;
        }

        return `${this.title} ${moment.utc(this.starts_at).tz(this.meet.timezone).format('ddd h:mm a')}`;
    }

    static searchMeet(meetId, search) {
        return new Promise(resolve => {
            const query = Session.query();

            if (search) {
                query.whereHas('flights', query =>
                    query.whereHas('start_lists', query =>
                        query.whereHas('athlete', query =>
                            query.where(
                                athlete =>
                                    athlete.name.toLowerCase().startsWith(search) ||
                                    athlete.last_name.toLowerCase().startsWith(search),
                            ),
                        ),
                    ),
                );
            }

            const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });

            resolve(
                query
                    .with('flights', query => {
                        if (!search) {
                            return true;
                        }

                        return query.whereHas('start_lists', query =>
                            query.whereHas('athlete', query =>
                                query.where(
                                    athlete =>
                                        athlete.name.toLowerCase().startsWith(search) ||
                                        athlete.last_name.toLowerCase().startsWith(search),
                                ),
                            ),
                        );
                    })
                    .with('flights.start_lists', query => query.orderBy('order'))
                    .with('flights.start_lists.athlete')
                    .with('flights.start_lists.athlete2')
                    .with('flights.start_lists.club')
                    .with('flights.start_lists.club2')
                    .with('flights.start_lists.result')
                    .where('meet_id', meetId)
                    .get()
                    .sort((a, b) => collator.compare(a.original_title, b.original_title)),
            );
        });
    }

    visibleFlights(event, search) {
        const flights = this.flights.filter(flight => flight.event === event);

        if (!search) {
            return flights;
        }

        const allFlightIds = _.map(
            StartListModel.query()
                .where('meet_id', this.meet_id)
                .whereHas('athlete', query =>
                    query
                        .where('last_name', name => name.startsWith(search))
                        .orWhere(athlete => athlete.name.startsWith(search)),
                )
                .get(),
            'flight_id',
        );

        const flightIds = _.uniq(allFlightIds);

        return flights.filter(flight => flightIds.includes(flight.id));
    }

    titleWithTime(meet, format = 'ddd h:mm a') {
        if (this.starts_at) {
            return `${this.title} - ${moment.utc(this.starts_at).tz(meet.timezone).format(format)}`;
        }

        if (this.flights.length > 0) {
            const firstFlight = this.flights[0];

            return `${this.title} - ${moment.utc(firstFlight.starts_at).tz(meet.timezone).format(format)}`;
        }

        if (this.ends_at) {
            return `${this.title} - ${moment.utc(this.ends_at).tz(meet.timezone).format('dddd')}`;
        }

        return this.title;
    }

    ordinalWithTime(meet, format = 'ddd h:mm a') {
        if (this.starts_at) {
            return `${this.ordinal} - ${moment.utc(this.starts_at).tz(meet.timezone).format(format)}`;
        }

        if (this.flights.length > 0) {
            const firstFlight = this.flights[0];

            return `${this.ordinal} - ${moment.utc(firstFlight.starts_at).tz(meet.timezone).format(format)}`;
        }

        if (this.ends_at) {
            return `${this.ordinal} - ${moment.utc(this.ends_at).tz(meet.timezone).format('dddd')}`;
        }

        return this.ordinal;
    }

    day(meet) {
        if (this.starts_at) {
            return moment.utc(this.starts_at).tz(meet.timezone).format('dddd');
        }

        if (this.flights.length > 0) {
            const firstFlight = this.flights[0];

            return moment.utc(firstFlight.starts_at).tz(meet.timezone).format('dddd');
        }

        return '';
    }
}
