import Model from '@/Models/Model.js';
import JobApplication from '@/Models/JobApplication.js';

export default class Job extends Model {
    static entity = 'jobs';

    static modelName = 'Job';

    static fields() {
        return {
            id: this.attr(null),
            club_id: this.attr(null),
            title: this.attr(''),
            location: this.attr(''),
            employment_type: this.attr(''),
            url: this.attr(''),
            salary: this.attr(''),
            contact_name: this.attr(''),
            contact_email: this.attr(''),
            contact_phone: this.attr(''),
            short_description: this.attr(''),
            description: this.attr(''),

            created_by: this.attr(''),
            updated_by: this.attr(''),

            ...super.fields(),

            applications: this.hasMany(JobApplication, 'job_id'),
        };
    }
}
