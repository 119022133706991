import Model from '@/Models/Model.js';
import Meet from '@/Models/Meet.js';
import MeetTeamQualifier from '@/Models/MeetTeamQualifier.js';

export default class TeamQualifier extends Model {
    static entity = 'team-qualifiers';

    static fields() {
        return {
            id: this.attr(null),
            title: this.attr(null),
            description: this.attr(null),
            key: this.attr(null),
            competition_starts_at: this.attr(null),
            competition_ends_at: this.attr(null),
            decided_at: this.attr(null),
            marked_official_at: this.attr(null),
            configs: this.attr(null),
            created_at: this.string(null),
            updated_at: this.string(null),

            meets: this.belongsToMany(Meet, MeetTeamQualifier, 'meet_id', 'team_qualifier_id'),
        };
    }
}
