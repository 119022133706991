import Model from '@/Models/Model.js';

export default class Affiliation extends Model {
    static entity = 'affiliations';

    static fields() {
        return {
            id: this.attr(null),
            user_id: this.attr(null),
            affiliate_id: this.attr(null),
            affiliate_type: this.attr(null),
            affiliate_type_entity: this.attr(null),
            verified_at: this.attr(null),

            affiliate: this.morphTo('affiliate_id', 'affiliate_type_entity'),
        };
    }

    get is_athlete_or_club() {
        return this.affiliate_type === 'App\\Models\\Athlete' || this.affiliate_type === 'App\\Models\\Club';
    }
}
