import Model from '@/Models/Model.js';

export default class Media extends Model {
    static entity = 'media';

    static fields() {
        return {
            id: this.attr(null),
            model_type: this.string(''),
            model_type_entity: this.string(''),
            model_id: this.attr(null),
            uuid: this.string(''),
            public_url: this.string(''),
            collection_name: this.string(''),
            name: this.string(''),
            file_name: this.string(''),
            mime_type: this.string(''),
            disk: this.string(''),
            conversions_disk: this.string(''),
            size: this.number(0),
            manipulations: this.string(''),
            custom_properties: this.string(''),
            responsive_images: this.string(''),
            order_column: this.number(1),

            deleted_at: this.string(''),
            created_at: this.string(''),
            updated_at: this.string(''),
        };
    }
}
