/* eslint-disable import/prefer-default-export */

/**
 * Imports the given page component from the page record.
 */
export function resolvePageComponent(name, pages) {
    const pageName = `${name.replace('.', '/')}.vue`;

    const foundPath = Object.keys(pages).find(path => path.replace('../Pages/', '') === pageName);

    if (foundPath) {
        return typeof pages[foundPath] === 'function' ? pages[foundPath]() : pages[foundPath];
    }

    throw new Error(`Page not found: ${name}`);
}
