import Model from '@/Models/Model.js';

export default class Activity extends Model {
    static entity = 'activity_log';

    static fields() {
        return {
            id: this.attr(null),
            log_name: this.attr(null),
            description: this.attr(null),
            subject_type: this.attr(null),
            subject_id: this.attr(null),
            event: this.attr(null),
            causer_type: this.attr(null),
            causer_id: this.attr(null),
            properties: this.attr(null),
            batch_uuid: this.attr(null),

            created_at: this.attr(null),
            updated_at: this.attr(null),
        };
    }
}
