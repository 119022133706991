import Model from '@/Models/Model.js';

export default class Country extends Model {
    static entity = 'countries';

    static modelName = 'Country';

    static fields() {
        return {
            id: this.attr(null),
            name: this.attr(null),
            alpha2: this.attr(null),
            alpha3: this.attr(null),
            ioc: this.attr(null),
            img: this.attr(null),
            img_src_set: this.attr(null),

            ...super.fields(),
        };
    }
}
