import numeral from 'numeral';
import BaseScore from '@/Models/BaseScore.js';
import TrampolineSkillDeductions from '@/Models/TrampolineSkillDeductions.js';

export default class TrampolineScore extends BaseScore {
    static entity = 'trampoline-scores';

    constructor(record) {
        super(record);

        this.nova_resource = 'trampoline-scores';
        this.className = 'App\\Models\\TrampolineScore';
    }

    static fields() {
        return {
            ...super.fields(),
            hd: this.attr(null),
            tof: this.attr(null),

            trampoline_skill_deductions: this.hasMany(TrampolineSkillDeductions, 'trampoline_score_id'),
        };
    }

    get skill_deductions() {
        return this.trampoline_skill_deductions;
    }

    format(field) {
        if (field === 'tof') {
            return numeral(this[field]).format('0.000');
        }

        return numeral(this[field]).format('0.0');
    }
}
