import moment from 'moment-timezone';
import { snakeCase } from 'change-case';
import Model from '@/Models/Model.js';
import { eventFromProscore } from '@/helpers.js';

export default class ActivityLogItem extends Model {
    static entity = 'activity_log_item';

    static fields() {
        return {
            id: this.attr(null),
            activity: this.attr(null),
            subject: this.attr(null),
            type: this.attr(null),
            athlete: this.attr(null),
            result_category: this.attr(null),
        };
    }

    get color() {
        switch (this.event) {
            case 'created':
                return 'green';
            case 'updated':
                return 'orange';
            case 'deleted':
                return 'red';
            default:
                return 'blue-grey';
        }
    }

    get is_created() {
        return this.activity.event === 'created';
    }

    get is_updated() {
        return this.activity.event === 'updated';
    }

    get event() {
        return this.activity.event;
    }

    get created() {
        return moment(this.activity.created_at).format('LLL zz');
    }

    get base_type() {
        return this.activity.subject_type.split('\\').pop();
    }

    get old_detail_headers() {
        return this.base_type === 'Result' ? this.resultHeaders('old') : this.routineHeaders('old');
    }

    get new_detail_headers() {
        return this.base_type === 'Result' ? this.resultHeaders('attributes') : this.routineHeaders('attributes');
    }

    resultHeaders(property) {
        return Object.keys(this.activity.properties[property]).filter(key => !['flashed_at', 'is_test'].includes(key));
    }

    routineHeaders(property) {
        return Object.keys(this.activity.properties[property]).filter(key => !['flashed_at', 'is_test'].includes(key));
    }

    get subject_id() {
        return this.activity.subject_id;
    }

    get description() {
        return this[`${snakeCase(this.base_type)}_description`];
    }

    get result_description() {}

    get trampoline_score_description() {}

    get double_mini_score_description() {}

    get tumbling_score_description() {}

    get synchro_score_description() {}

    get club_description() {}

    get athlete_description() {
        let description = `${this.athlete?.name || 'Unknown Athlete'} `;

        const scoreDetails =
            this.activity.event === 'created' ? this.activity.properties.attributes : this.activity.properties.old;

        if (this.activity.event === 'created' || this.activity.event === 'updated') {
            description += `${
                this.activity.event === 'created' ? 'scored' : 'had their score updated to'
            } ${this.resultScore(scoreDetails)} on ${eventFromProscore(scoreDetails.event, 'long')}. `;
        } else {
            description += `Event: ${this.activity.event}`;
        }

        return description;
    }

    get start_list_description() {
        let description = `${this.subject_id}: ${this.athlete?.name || 'Unknown Athlete'} (#${this.athlete?.id}) `;

        const scoreDetails =
            this.activity.event === 'created' ? this.activity.properties.attributes : this.activity.properties.old;

        if (this.activity.event === 'created' || this.activity.event === 'updated') {
            description += `${
                this.activity.event === 'created' ? 'scored' : 'had their start list updated to'
            } ${this.resultScore(scoreDetails)} on ${eventFromProscore(scoreDetails.event, 'long')}. `;
        }

        return description;
    }

    get flight_description() {}

    get session_description() {}
}
