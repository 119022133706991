import Model from '@/Models/Model.js';

export default class Role extends Model {
    static entity = 'roles';

    static modelName = 'Role';

    static fields() {
        return {
            id: this.attr(null),
            name: this.attr(null),
            guard_name: this.attr(null),

            ...super.fields(),

            permissions: this.attr(null),
        };
    }
}
