export default [
    'night-sky',
    'sunset-sky',
    'rainforest',
    'cosmic-blue',
    'cotton-candy-sky',
    'cotton-candy',
    'phoenix',
    'pastel-pink',
    'deep-ocean',
    'merlot',
    'steel-blue',
    'crimson',
    'rainbow-sherbet',
];
