import Model from '@/Models/Model.js';
import User from '@/Models/User.js';

export default class Subscription extends Model {
    static entity = 'subscriptions';

    static fields() {
        return {
            id: this.attr(null),
            user_id: this.attr(null),
            name: this.attr(null),
            stripe_id: this.attr(null),
            stripe_status: this.attr(null),
            stripe_price: this.attr(null),
            quantity: this.attr(null),
            trial_ends_at: this.attr(null),
            ends_at: this.attr(null),

            created_at: this.string(null),
            updated_at: this.string(null),

            user: this.hasMany(User, 'user_id'),
        };
    }
}
