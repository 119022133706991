import Model from '@/Models/Model.js';

export default class TeamDefinitionSetting extends Model {
    static entity = 'team_definition_settings';

    static modelName = 'TeamDefinitionSetting';

    static fields() {
        return {
            id: this.attr(null),
            meet_id: this.attr(null),
            title: this.attr(null),
            levels: this.attr(null),
            genders: this.attr(null),
            events: this.attr(null),
            aggregate: this.attr(null),
            min_age: this.attr(null),
            max_age: this.attr(null),

            ...super.fields(),
        };
    }
}
