import Vue from 'vue';
import { Link } from '@inertiajs/vue2';

Vue.component('RouterLink', {
    functional: true,

    render(h, context) {
        const data = { ...context.data };
        delete data.nativeOn;
        const props = data.props || {};
        props.href = props.to; /// v-btn passes `to` prop but inertia-link requires `href`, so we just copy it
        return h('inertia-link', data, context.children);
    },
});

Vue.component('InertiaLink', Link);

Vue.mixin({
    computed: {
        $routeParams() {
            return Object.fromEntries(new URLSearchParams(window.location.search));
        },
    },
});
