import * as Sentry from '@sentry/vue';
import Vue from 'vue';
import { route } from 'ziggy-js';
import { router } from '@inertiajs/vue2';

Sentry.init({
    Vue,
    dsn: 'https://ea53fc0219a0490cbbcf7e07133b79b3@o353433.ingest.us.sentry.io/5427609',
    environment: import.meta.env.APP_ENV,
    integrations: [
        Sentry.browserTracingIntegration({
            // disable automatic span creation
            instrumentNavigation: false,
            instrumentPageLoad: false,
        }),
        Sentry.replayIntegration({
            // Additional SDK configuration goes in here, for example:
            maskAllText: true,
            blockAllMedia: true,
        }),
    ],
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // We recommend adjusting this value in production, or using tracesSampler for finer control
    tracesSampleRate: 1.0,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['scorsync-online.test', /^https:\/\/scorsync\.com\/api/],
    logErrors: import.meta.env.APP_ENV === 'production',
});

let name = route().current();

const client = Sentry.getClient();
Sentry.startBrowserTracingPageLoadSpan(client, { op: 'pageload', name });

export default client;

router.on('before', () => {
    const newName = route().current();
    if (newName !== name) {
        name = newName;
        // every time the route changes, we trigger a navigation
        Sentry.startBrowserTracingNavigationSpan(client, {
            op: 'navigation',
            name,
            attributes: {
                [Sentry.SEMANTIC_ATTRIBUTE_SENTRY_SOURCE]: 'route',
            },
        });
    }
});

router.on('finish', () => {
    name = route().current();
    // always make sure we are using the correct route name
    const span = Sentry.getActiveSpan();
    const op = span && Sentry.spanToJSON(span).op;

    if (op === 'pageload' || op === 'navigation') {
        span.setAttribute('name', name);
    }
});
