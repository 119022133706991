import Model from '@/Models/Model.js';

export default class TrampolineSkillDeductions extends Model {
    static entity = 'trampoline_skill_deductions';

    static modelName = 'TrampolineSkillDeductions';

    static fields() {
        return {
            id: this.attr(null),
            athlete_id: this.attr(null),
            club_id: this.attr(null),
            result_id: this.attr(null),
            trampoline_score_id: this.attr(null),
            num: this.attr(null),
            position: this.attr(null),
            is_tc: this.attr(null),
            s1: this.attr(null),
            s2: this.attr(null),
            s3: this.attr(null),
            s4: this.attr(null),
            s5: this.attr(null),
            s6: this.attr(null),
            s7: this.attr(null),
            s8: this.attr(null),
            s9: this.attr(null),
            s10: this.attr(null),
            landing: this.attr(null),
            dd: this.attr(null),
            final: this.attr(null),

            ...super.fields(),
        };
    }
}
