import Vue from 'vue';
import Echo from 'laravel-echo';

// Reverb
Vue.prototype.$echo = new Echo({
    broadcaster: 'reverb',
    key: import.meta.env.VITE_REVERB_APP_KEY,
    wsHost: import.meta.env.VITE_REVERB_HOST,
    wsPort: import.meta.env.VITE_REVERB_PORT ?? 80,
    wssPort: import.meta.env.VITE_REVERB_PORT ?? 443,
    forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
});

// Pusher
// Vue.prototype.$echo = new Echo({
//     broadcaster: 'pusher',
//     key: window.configs.pusher.key,
//     cluster: window.configs.pusher.cluster,
//     forceTLS: window.configs.pusher.forceTLS,
//     namespace: 'App.Events',
// });
