import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';
import {
    TiptapVuetifyPlugin,
    Heading,
    Bold,
    Italic,
    Strike,
    Underline,
    Code,
    Paragraph,
    BulletList,
    OrderedList,
    ListItem,
    Link,
    Blockquote,
    HardBreak,
    HorizontalRule,
    History,
} from 'tiptap-vuetify';
import 'tiptap-vuetify/dist/main.css';

Vue.use(Vuetify);

const options = {
    theme: {
        themes: {
            light: {
                primary: colors.blueGrey.darken4,
                secondary: colors.orange.base,
                accent: colors.blue.base,
                error: colors.red.base,
                warning: colors.deepOrange.base,
                info: colors.cyan.base,
                success: colors.green.base,
                background: colors.red.lighten3,
            },
            dark: {
                primary: colors.blueGrey,
                anchor: colors.blueGrey.lighten3,
                background: colors.indigo.base,
            },
        },
    },
    icons: {
        iconfont: 'md', // default - only for display purposes
    },
};

const vuetify = new Vuetify(options);

Vue.use(TiptapVuetifyPlugin, {
    vuetify,
    iconsGroup: 'md',
});

export const defaultTipTapToolbar = [
    History,
    Blockquote,
    Link,
    Underline,
    Strike,
    Italic,
    ListItem,
    BulletList,
    OrderedList,
    [
        Heading,
        {
            options: {
                levels: [1, 2, 3],
            },
        },
    ],
    Bold,
    Code,
    HorizontalRule,
    Paragraph,
    HardBreak,
];

export const defaultAttrs = vm => {
    return vm.$vuetify.theme.isDark ? { color: 'grey darken-3', dark: true } : { color: 'grey lighten-1' };
};

export default vuetify;
