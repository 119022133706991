import { Model as VuexOrmModel } from '@vuex-orm/core';
import moment from 'moment-timezone';

/**
 * @typedef {import('moment-timezone').Moment} Moment
 */

export default class Model extends VuexOrmModel {
    static fields() {
        return {
            created_at: this.attr(null),
            updated_at: this.attr(null),
        };
    }

    /**
     * Returns a moment object for the specified field
     * @param field
     * @param tz
     * @returns {Moment}
     */
    timestamp(field, tz = 'America/Los_Angeles') {
        const value = this[field];

        return moment.utc(value).tz(tz);
    }

    localTimestamp(field) {
        return moment(this[field]);
    }

    // static beforeSelect(models) {
    //     console.log(this.entity, models.length);
    //
    //     return models;
    // }
}
