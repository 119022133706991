import numeral from 'numeral';
import { capitalCase } from 'change-case';
import moment from 'moment-timezone';
import Model from '@/Models/Model.js';
import Result from '@/Models/Result.js';
import Video from '@/Models/Video.js';

export default class Score extends Model {
    static fields() {
        return {
            id: this.attr(null),
            result_id: this.attr(null),
            num: this.number(null),
            round: this.string(null),
            e1: this.attr(null),
            e2: this.attr(null),
            e3: this.attr(null),
            dd: this.attr(null),
            nd: this.attr(null),
            execution: this.attr(null),
            total: this.attr(null),
            flashed_at: this.attr(null),
            score_changed: this.boolean(false),
            score_changes: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),

            result: this.belongsTo(Result, 'result_id'),
            video: this.morphOne(Video, 'routine_id', 'routine_type_entity'),
        };
    }

    formatted(field) {
        const precision3 = [
            'tof',
            'prelims_score',
            'finals_score',
            'total_score',
            'execution',
            'new_life_finals_score',
            'total',
        ];
        const precision2 = ['hd', 'sync1', 'sync2', 'sync'];

        if (precision3.includes(field)) {
            return numeral(this[field]).format('0.000');
        }

        if (precision2.includes(field)) {
            return numeral(this[field]).format('0.00');
        }

        return numeral(this[field]).format('0.0');
    }

    userCanModifyVideo(user, result) {
        let computedResult = result;

        if (!user || (!result && !this.result)) {
            return false;
        }

        if (user.id === 1 || (user.roles && user.roles.map(role => role.name).includes('Videographer'))) {
            return true;
        }

        if (!result) {
            computedResult = this.result;
        }

        const athleteIds = _.filter([computedResult.athlete_id, computedResult.athlete_2_id]);

        const clubIds = _.filter([computedResult.club_id, computedResult.club_2_id]);

        return _.some(user.affiliations, affiliation => {
            if (affiliation.affiliate_type === 'App\\Models\\Athlete') {
                return athleteIds.includes(affiliation.affiliate_id);
            }

            return clubIds.includes(affiliation.affiliate_id);
        });
    }

    get round_display() {
        return capitalCase(this.round);
    }

    get latest_round_title() {
        return {
            prelims: 'Qualification',
            semi_final: 'Q2',
            finals: 'Final',
            final_one: 'Final One',
            final_two: 'Final Two',
        }[this.round];
    }

    get latest_round_short() {
        return {
            prelims: 'Qual',
            semi_final: 'SF',
            finals: 'Final',
            final_one: 'F1',
            final_two: 'F2',
        }[this.round];
    }

    changed(thresholdMinutes = 60) {
        // If the routine was last updated more than 1 hour ago, we don't need to check for changes
        if (moment().subtract(thresholdMinutes, 'minutes').isAfter(this.timestamp('updated_at'))) {
            return false;
        }

        return this.score_changed ? this.score_changes[0] : null;
    }

    changedFields(thresholdMinutes = 60) {
        const oldValues = this.changed(thresholdMinutes)?.old;

        if (!oldValues) {
            return [];
        }

        return Object.keys(oldValues).filter(change => !['e1', 'e2', 'e3', 'e4'].includes(change));
    }

    static routineModel(event) {
        return {
            TI: 'App\\Models\\TrampolineScore',
            DM: 'App\\Models\\DoubleMiniScore',
            TU: 'App\\Models\\TumblingScore',
            TS: 'App\\Models\\SynchroScore',
        }[event];
    }
}
