import numeral from 'numeral';
import moment from 'moment-timezone';
import Model from '@/Models/Model.js';
import Meet from '@/Models/Meet.js';
import ResultCategory from '@/Models/ResultCategory.js';
import Athlete from '@/Models/Athlete.js';
import Club from '@/Models/Club.js';
import TrampolineScore from '@/Models/TrampolineScore.js';
import DoubleMiniScore from '@/Models/DoubleMiniScore.js';
import TumblingScore from '@/Models/TumblingScore.js';
import SynchroScore from '@/Models/SynchroScore.js';
import levels from '@/levels.json';
import Video from '@/Models/Video.js';
import { ordinal } from '@/helpers.js';
import { keyedLevels } from '@/levels.js';

export default class Result extends Model {
    static entity = 'results';

    static fields() {
        return {
            id: this.attr(null),
            athlete_id: this.attr(null),
            athlete_2_id: this.attr(null),
            meet_id: this.attr(null),
            club_id: this.attr(null),
            club_2_id: this.attr(null),
            category_id: this.attr(null),
            prelims_start_list_id: this.attr(null),
            semi_final_start_list_id: this.attr(null),
            finals_start_list_id: this.attr(null),
            final_one_start_list_id: this.attr(null),
            final_two_start_list_id: this.attr(null),
            competed: this.attr(null),
            had_finals: this.attr(null),
            event: this.attr(null),
            level: this.attr(null),
            age_group: this.attr(null),
            gender: this.attr(null),
            prelims_score: this.attr(null),
            semi_final_score: this.attr(null),
            finals_score: this.attr(null),
            final_one_score: this.attr(null),
            final_two_score: this.attr(null),
            total_score: this.attr(null),
            prelims_placement: this.attr(null),
            semi_final_placement: this.attr(null),
            finals_placement: this.attr(null),
            final_one_placement: this.attr(null),
            final_two_placement: this.attr(null),
            prelims_rank: this.attr(null),
            prelims_qual: this.attr(null),
            semi_final_rank: this.attr(null),
            semi_final_qual: this.attr(null),
            finals_rank: this.attr(null),
            final_one_rank: this.attr(null),
            final_one_qual: this.attr(null),
            final_two_rank: this.attr(null),
            mobilized: this.boolean(false),
            qualified: this.boolean(false),
            us_citizen: this.boolean(true),
            selection_score_report: this.attr(null),
            equipment_identifier: this.string('A'),

            created_at: this.string(null),
            updated_at: this.string(null),

            athlete: this.belongsTo(Athlete, 'athlete_id'),
            athlete2: this.belongsTo(Athlete, 'athlete_2_id'),
            meet: this.belongsTo(Meet, 'meet_id'),
            club: this.belongsTo(Club, 'club_id'),
            club2: this.belongsTo(Club, 'club_2_id'),
            category: this.belongsTo(ResultCategory, 'category_id'),
            videos: this.hasMany(Video, 'result_id'),

            trampoline_scores: this.hasMany(TrampolineScore, 'result_id'),
            double_mini_scores: this.hasMany(DoubleMiniScore, 'result_id'),
            tumbling_scores: this.hasMany(TumblingScore, 'result_id'),
            synchro_scores: this.hasMany(SynchroScore, 'result_id'),
        };
    }

    get routine_results() {
        const events = {
            TI: this.trampoline_scores,
            DM: this.double_mini_scores,
            TU: this.tumbling_scores,
            TS: this.synchro_scores,
        };

        return events[this.event];
    }

    get latest_score() {
        return _.first(_.orderBy(this.routine_results, ['updated_at', 'num'], ['desc', 'desc']));
    }

    get latest_round() {
        if (this.final_two_score) {
            return 'final_two';
        }

        if (this.final_one_score) {
            return 'final_one';
        }

        if (this.finals_score) {
            return 'finals';
        }

        if (this.semi_final_score) {
            return 'semi_final';
        }

        return 'prelims';
    }

    get latest_round_title() {
        return {
            prelims: 'Qualification',
            semi_final: 'Q2',
            finals: 'Final',
            final_one: 'Final One',
            final_two: 'Final Two',
        }[this.latest_round];
    }

    get latest_round_short() {
        return {
            prelims: 'Q',
            semi_final: 'SF',
            finals: 'F',
            final_one: 'F1',
            final_two: 'F2',
        }[this.latest_round];
    }

    get latest_round_display() {
        return {
            prelims: 'Qualification',
            semi_final: 'Q2',
            finals: 'Final',
            final_one: 'Final One',
            final_two: 'Final Two',
        }[this.latest_round];
    }

    get latest_ordinal_rank() {
        return this.ordinalRank([`${this.latest_round}_placement`]);
    }

    get event_display() {
        const events = {
            TI: 'tra',
            DM: 'dmt',
            TU: 'tum',
            TS: 'syn',
        };

        return events[this.event];
    }

    get event_title() {
        return {
            TI: 'Trampoline',
            DM: 'Double Mini',
            TU: 'Tumbling',
            TS: 'Synchro',
        }[this.event];
    }

    get has_ordinal_placement() {
        return !this.overall_placement.endsWith('T') && this.overall_placement !== 'N/A';
    }

    get highlighted() {
        const urlParams = new URLSearchParams(window.location.search);
        const highlight = urlParams.get('highlight');

        if (!highlight || Number.parseInt(highlight, 10) !== this.id) {
            return false;
        }

        return ['highlighted'];
    }

    get overall_placement() {
        return (
            this.final_two_placement ||
            this.final_one_placement ||
            this.finals_placement ||
            this.semi_final_placement ||
            this.prelims_placement ||
            'N/A'
        );
    }

    get overall_rank() {
        return (
            this.final_two_rank ||
            this.final_one_rank ||
            this.finals_rank ||
            this.semi_final_rank ||
            this.prelims_rank ||
            'N/A'
        );
    }

    get level_title() {
        return this.level_info?.label || this.level;
    }

    get level_info() {
        return _.find(levels, level => level.short === this.level);
    }

    get has_not_competed_finals() {
        return !this.semi_final_score && !this.finals_score && !this.final_one_score && !this.final_two_score;
    }

    routineResultsForRound(round) {
        return this.routine_results.filter(routine => routine.round === round).sort((a, b) => a.num - b.num);
    }

    ordinalRank(field) {
        if (this[field] === null) {
            return 'N/A';
        }

        if (this[field].includes('T')) {
            return this[field];
        }

        const n = parseInt(this[field], 10);

        // eslint-disable-next-line
        return `${n}${[, 'st', 'nd', 'rd'][(n / 10) % 10 ^ 1 && n % 10] || 'th'}`;
    }

    eventualScore(providedMeet) {
        const meet = providedMeet || this.meet;
        let score;

        if (this.final_two_score) {
            score = this.final_two_score;
        }

        if (this.final_one_score) {
            score = this.final_one_score;
        }

        if (this.finals_score) {
            if ((this.is_elite && meet.elite_new_life_finals) || (!this.is_elite && meet.jo_new_life_finals)) {
                score = this.finals_score;
            }
        }

        if (this.semi_final_score) {
            if ((this.is_elite && meet.elite_new_life_finals) || (!this.is_elite && meet.jo_new_life_finals)) {
                score = this.semi_final_score;
            }
        }

        if (!score) {
            score = this.prelims_score;
        }

        return numeral(score).format('0.000');
    }

    static headers(meet, event, round) {
        let headers;

        if (event === 'TS') {
            headers = Result.synchro_athlete_headers(meet, round);
        } else {
            headers = [
                {
                    text: '',
                    value: 'image',
                },
                {
                    text: 'Name',
                    value: 'athlete.name',
                },
                {
                    text: meet.is_international ? 'Country' : 'Club',
                    value: 'club.name',
                },
            ];
        }

        const events = {
            TI: Result.trampoline_headers(meet, round),
            DM: Result.double_mini_headers(meet, round),
            TU: Result.tumbling_headers(meet, round),
            TS: Result.synchro_headers(meet, round),
        };

        events[event].forEach(header => headers.push(header));

        return headers;
    }

    get medal_color() {
        return Result.medalColor(this.overall_placement);
    }

    get route() {
        return `/result-categories/${this.category_id}`;
    }

    get is_elite() {
        return keyedLevels[this.level].isElite;
    }

    shareableMetadata(round) {
        const videos = this.videos.length > 0 ? this.videos : Video.query().where('result_id', this.id).get();

        const score = this[`${round}_score`];
        const placement = this[`${round}_placement`];

        const category = this.category || ResultCategory.query().where('id', this.category_id).first();

        return {
            title: `${ordinal(placement)} - ${this.athlete.name} (${category.event_short_title}) - ${score}`,
            description: category.description(),
            images: videos.map(video => video.thumbnailUrl).filter(url => url !== ''),
            videos: videos.map(video => video.hls_url).filter(url => url !== ''),
            url: `${window.location.href}&share=${this.id}`,
        };
    }

    changed(round = 'prelims', thresholdMinutes = 60) {
        // If the result was last updated more than 1 hour ago, we don't need to check for changes
        if (moment().subtract(thresholdMinutes, 'minutes').isAfter(this.timestamp('updated_at'))) {
            return false;
        }

        return this.routineResultsForRound(round)
            .map(routine => routine.score_changed)
            .some(changed => changed === true);
    }

    static medalColor(placement) {
        if (String(placement) === '1' || String(placement) === '1T' || String(placement) === '1F') {
            return 'yellow darken-2';
        }

        if (String(placement) === '2' || String(placement) === '2T' || String(placement) === '2F') {
            return 'grey';
        }

        if (String(placement) === '3' || String(placement) === '3T' || String(placement) === '3F') {
            return 'brown';
        }

        return '';
    }

    static trampoline_headers(meet, round) {
        const headers = [];

        if (round === 'finals') {
            headers.push({
                text: 'Round',
                value: 'round',
            });
        }

        headers.push({
            text: '#',
            value: 'num',
        });

        return [
            ...headers,
            ...[
                {
                    text: 'Execution',
                    value: 'execution',
                },
                {
                    text: 'HD',
                    value: 'hd',
                },
                {
                    text: 'ToF',
                    value: 'tof',
                },
                {
                    text: 'DD',
                    value: 'dd',
                },
                {
                    text: 'ND',
                    value: 'nd',
                    align: 'end',
                },
                {
                    text: 'Score',
                    value: 'score',
                },
            ],
        ];
    }

    static double_mini_headers(meet, round) {
        const headers = [];

        if (round === 'finals') {
            headers.push({
                text: 'Round',
                value: 'round',
            });
        }

        headers.push({
            text: '#',
            value: 'num',
        });

        headers.push({
            text: 'Execution',
            value: 'execution',
        });

        headers.push({
            text: 'DD',
            value: 'dd',
        });

        headers.push({
            text: 'ND',
            value: 'nd',
            align: 'end',
        });

        headers.push({
            text: 'Score',
            value: 'score',
        });

        return headers;
    }

    static tumbling_headers(meet, round) {
        const headers = [];

        if (round === 'finals') {
            headers.push({
                text: 'Round',
                value: 'round',
            });
        }

        headers.push({
            text: '#',
            value: 'num',
        });

        headers.push({
            text: 'Execution',
            value: 'execution',
        });

        headers.push({
            text: 'DD',
            value: 'dd',
        });

        headers.push({
            text: 'ND',
            value: 'nd',
            align: 'end',
        });

        headers.push({
            text: 'Score',
            value: 'score',
        });

        return headers;
    }

    static synchro_athlete_headers() {
        return [
            {
                text: '',
                value: 'synchro_images',
            },
            {
                text: 'Pair',
                value: 'synchro_pair',
            },
            {
                text: 'Clubs',
                value: 'synchro_clubs',
            },
        ];
    }

    static synchro_headers(meet, round) {
        const headers = [];

        if (round === 'finals') {
            headers.push({
                text: 'Round',
                value: 'round',
            });
        }

        return [
            ...headers,
            ...[
                {
                    text: '#',
                    value: 'num',
                },
                {
                    text: 'Execution',
                    value: 'execution',
                },
                {
                    text: 'HD1',
                    value: 'hd1',
                },
                {
                    text: 'HD2',
                    value: 'hd2',
                },
                {
                    text: 'Sync 1',
                    value: 'sync1',
                },
                {
                    text: 'Sync 2',
                    value: 'sync2',
                },
                {
                    text: 'DD',
                    value: 'dd',
                },
                {
                    text: 'ND',
                    value: 'nd',
                    align: 'end',
                },
                {
                    text: 'Score',
                    value: 'score',
                },
            ],
        ];
    }
}
