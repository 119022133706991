import levels from '@/levels.json';

export default levels;

const keyedLevels = {};

levels.forEach(level => {
    keyedLevels[String(level.short)] = level;
});

export { keyedLevels };
