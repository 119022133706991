import Score from '@/Models/BaseScore.js';
import TumblingSkillDeductions from '@/Models/TumblingSkillDeductions.js';

export default class TumblingScore extends Score {
    static entity = 'tumbling-scores';

    constructor(record) {
        super(record);

        this.nova_resource = 'tumbling-scores';
        this.className = 'App\\Models\\TumblingScore';
    }

    static fields() {
        return {
            ...super.fields(),

            tumbling_skill_deductions: this.hasMany(TumblingSkillDeductions, 'tumbling_score_id'),
        };
    }

    get skill_deductions() {
        return this.tumbling_skill_deductions;
    }
}
