import Vue from 'vue';
import VueFullscreen from 'vue-fullscreen';
import VueSocialSharing from 'vue-social-sharing';
import VueClipboard from 'vue-clipboard2';
import VueScrollTo from 'vue-scrollto';
import Vuex from 'vuex';
import PortalVue from 'portal-vue';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
import ZiggyPlugin from '@/Plugins/ziggy-plugin.js';
import CookieConsent from '@/Plugins/cookieconsent-plugin.js';

Vue.use(VueFullscreen);
Vue.use(VueSocialSharing);
Vue.use(VueClipboard);
Vue.use(VueScrollTo);
Vue.use(ZiggyPlugin);
Vue.use(Vuex);
Vue.use(PortalVue);
Vue.use(VueMoment, { moment });

Vue.use(CookieConsent);
