// store/modules/videoPlayer.js
export default {
    namespaced: true,

    state: {
        videoDialog: false,
        videoData: null,
    },

    actions: {
        openVideo({ commit }, videoData) {
            commit('SET_VIDEO_DATA', videoData);
            commit('SET_VIDEO_DIALOG', true);
        },
        closeVideo({ commit }) {
            commit('SET_VIDEO_DIALOG', false);
            commit('SET_VIDEO_DATA', null);
        },
    },

    mutations: {
        SET_VIDEO_DIALOG(state, isOpen) {
            state.videoDialog = isOpen;
        },
        SET_VIDEO_DATA(state, data) {
            state.videoData = data;
        },
    },
};
