import 'babel-polyfill';

import Vue from 'vue';
import '@/bootstrap.js';

import '@/Bootstrap/plugins.js';
import '@/Bootstrap/sentry.js';
import '@/Bootstrap/echo.js';
import '@/Bootstrap/components.js';
import '@/Bootstrap/inertia.js';
import '@/Bootstrap/vue-global-utilities.js';

Vue.config.productionTip = false;

Vue.config.devtools =
    typeof import.meta.env.VITE_APP_DEBUG === 'string'
        ? import.meta.env.VITE_APP_DEBUG === 'true'
        : import.meta.env.VITE_APP_DEBUG;
