import MobilitySkill from './MobilitySkill.js';

export default class DoubleMiniMobilitySkill extends MobilitySkill {
    static entity = 'double-mini-mobility-skills';

    static fields() {
        return {
            id: this.attr(null),
            level_str: this.string(''),
            level_from: this.string(''),
            level_to: this.string(''),
            gender: this.string(''),
            skill_matrix: this.attr(null),
        };
    }
}
