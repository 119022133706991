import Model from '@/Models/Model.js';
import gradients from '@/gradients.js';
import Club from '@/Models/Club.js';
import Athlete from '@/Models/Athlete.js';

export default class User extends Model {
    static entity = 'users';

    static fields() {
        return {
            id: this.attr(null),
            name: this.attr(null),
            email: this.attr(null),
            email_verified_at: this.attr(null),
            password: this.attr(null),
            remember_token: this.attr(null),
            current_team_id: this.attr(null),
            profile_photo_path: this.attr(null),
            profile_photo_url: this.attr(null),
            firebase_push_id: this.attr(null),
            two_factor_secret: this.attr(null),
            two_factor_recovery_codes: this.attr(null),
            media: this.attr(null),
            is_subscribed: this.boolean(false),
            theme_preference: this.attr(null),

            ...super.fields(),

            permissions: this.attr(null),
            roles: this.attr(null),
            affiliations: this.hasMany('affiliations', 'user_id'),
            athlete_follows: this.hasMany('athlete-follows', 'user_id'),
            club_follows: this.hasMany('club-follows', 'user_id'),
            subscriptions: this.hasMany('subscriptions', 'user_id'),
            notification_preferences: this.hasOne('notification_preferences', 'user_id'),
        };
    }

    get avatar_color() {
        const colors = gradients;

        const sum = _.sum(this.name.split('').map(letter => letter.charCodeAt(0)));

        return colors[sum % colors.length];
    }

    get initials() {
        const names = this.name.split(' ');

        return names.map(name => name.charAt(0).toUpperCase()).join('');
    }

    get is_admin() {
        return [
            1, // Austin White
            1888, // Matt Browne
            172, // Patti Conner
            232, // Amy McDonald
            44, // Peter Dodd
        ].includes(this.id);
    }

    get athlete_affiliations() {
        return this.affiliations.filter(affiliation => affiliation.affiliate_type === 'App\\Models\\Athlete');
    }

    get club_affiliations() {
        return this.affiliations.filter(affiliation => affiliation.affiliate_type === 'App\\Models\\Club');
    }

    hasPermissionTo(permission) {
        if (this.is_admin) {
            return true;
        }

        return (
            _.find(this.permissions, { name: permission }) ||
            _.some(this.roles.map(role => _.find(role.permissions, { name: permission })))
        );
    }

    canEdit(model) {
        if (model instanceof Athlete) {
            return this.canEditAthlete(model);
        }
        if (model instanceof Club) {
            return this.canEditClub(model);
        }

        return false;
    }

    canEditAthlete(athlete) {
        if (this.id === 1) {
            return true;
        }

        if (!this.affiliations.length) {
            return false;
        }

        return (
            this.affiliations.filter(
                affiliation =>
                    (affiliation.affiliate_type === 'App\\Models\\Athlete' &&
                        affiliation.affiliate_id === athlete.id &&
                        affiliation.user_id === this.id &&
                        !!affiliation.verified_at) ||
                    (affiliation.affiliate_type === 'App\\Models\\Club' &&
                        affiliation.affiliate_id === athlete.club_id &&
                        affiliation.user_id === this.id &&
                        !!affiliation.verified_at),
            ).length > 0
        );
    }

    canEditClub(club) {
        if (this.id === 1) {
            return true;
        }

        if (!this.affiliations.length) {
            return false;
        }

        return (
            this.affiliations.filter(
                affiliation =>
                    affiliation.affiliate_type === 'App\\Models\\Club' &&
                    affiliation.affiliate_id === club.id &&
                    affiliation.user_id === this.id &&
                    !!affiliation.verified_at,
            ).length > 0
        );
    }
}
