import Model from '@/Models/Model.js';

export default class MobilitySkill extends Model {
    static entityName = 'mobility-skills';

    static fields() {
        return {
            id: this.attr(null),
            num: this.string(''),
            level_str: this.string(''),
            level_from: this.string(''),
            level_to: this.string(''),
            gender: this.string(''),
            skill: this.string(''),
            coach_status: this.string(''),
            judge_status: this.string(''),
            coach_notes: this.string(''),
            judge_notes: this.string(''),
        };
    }
}
