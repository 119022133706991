import BaseScore from '@/Models/BaseScore.js';
import SynchroSkillDeductions from '@/Models/SynchroSkillDeductions.js';

export default class SynchroScore extends BaseScore {
    static entity = 'synchro-scores';

    constructor(record) {
        super(record);

        this.nova_resource = 'synchro-scores';
        this.className = 'App\\Models\\SynchroScore';
    }

    static fields() {
        return {
            ...super.fields(),
            e4: this.attr(null),
            hd1: this.attr(null),
            hd2: this.attr(null),
            sync1: this.attr(null),
            sync2: this.attr(null),
            tof: this.attr(null),
            sync: this.attr(null),
            hd: this.attr(null),

            synchro_skill_deductions: this.hasMany(SynchroSkillDeductions, 'synchro_score_id'),
        };
    }

    get skill_deductions() {
        return this.synchro_skill_deductions;
    }
}
