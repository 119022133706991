import first from 'lodash/first.js';
import Model from '@/Models/Model.js';
import Meet from '@/Models/Meet.js';
import Athlete from '@/Models/Athlete.js';
import Club from '@/Models/Club.js';
import Session from '@/Models/Session.js';
import Flight from '@/Models/Flight.js';
import Result from '@/Models/Result.js';
import Video from '@/Models/Video.js';
import UploadVideo from '@/Models/UploadVideo.js';
import levels from '@/levels.json';
import { keyedLevels } from '@/levels.js';

export default class StartList extends Model {
    static entity = 'start_lists';

    static fields() {
        return {
            id: this.attr(null),
            meet_id: this.attr(null),
            athlete_id: this.attr(null),
            athlete_2_id: this.attr(null),
            club_id: this.attr(null),
            club_2_id: this.attr(null),
            session_id: this.attr(null),
            flight_id: this.attr(null),
            result_category_id: this.attr(null),
            result_id: this.attr(null),
            round: this.attr(null),
            event: this.attr(null),
            level: this.attr(null),
            age_group: this.attr(null),
            order: this.attr(null),
            gender: this.attr(null),
            equipment: this.attr(null),
            routines_completed: this.number(0),
            routines_to_complete: this.number(0),

            ...super.fields(),

            meet: this.belongsTo(Meet, 'meet_id'),
            athlete: this.belongsTo(Athlete, 'athlete_id'),
            athlete2: this.belongsTo(Athlete, 'athlete_2_id'),
            club: this.belongsTo(Club, 'club_id'),
            club2: this.belongsTo(Club, 'club_2_id'),
            session: this.belongsTo(Session, 'session_id'),
            flight: this.belongsTo(Flight, 'flight_id'),
            result: this.belongsTo(Result, 'result_id'),
            videos: this.hasMany(Video, 'start_list_id'),
            uploaded_videos: this.hasMany(UploadVideo, 'start_list_id'),
        };
    }

    get level_title() {
        return this.level_info.label;
    }

    get level_info() {
        return _.find(levels, level => level.short === this.level);
    }

    get is_elite() {
        return keyedLevels[this.level].isElite;
    }

    get event_short_title() {
        const map = {
            TI: 'TRA',
            DM: 'DMT',
            TU: 'TUM',
            TS: 'SYN',
        };

        return map[this.event];
    }

    description({ without } = {}) {
        if (this.result_category) {
            return this.result_category.description;
        }

        const description = [];

        if (!without || !without.includes('event')) {
            description.push(this.event_short_title);
        }

        if (!without || !without.includes('gender')) {
            description.push(this.gender.toUpperCase());
        }

        if (!without || !without.includes('level')) {
            description.push(this.level_title);
        }

        if ((!without || !without.includes('age_group')) && !this.is_elite) {
            description.push(this.age_group);
        }

        return description.join(' ');
    }

    get event_display() {
        const events = {
            TI: 'tra',
            DM: 'dmt',
            TU: 'tum',
            TS: 'syn',
        };

        return events[this.event];
    }

    get event_title() {
        return {
            TI: 'Trampoline',
            DM: 'Double Mini',
            TU: 'Tumbling',
            TS: 'Synchro',
        }[this.event];
    }

    get competitor() {
        if (!this.athlete) {
            return '';
        }

        if (this.event !== 'TS') {
            return this.athlete.name;
        }

        return `${this.athlete.name} & ${this.athlete2?.name}`;
    }

    get competitor_with_club() {
        if (!this.athlete) {
            return '';
        }

        if (this.event !== 'TS') {
            return `${this.athlete.name} - ${this.club.name}`;
        }

        return `${this.athlete.name} & ${this.athlete2.name} - ${this.club.name} & ${this.club2.name}`;
    }

    videoOfRoutine(num) {
        return first(this.videos.filter(video => video.routine?.num === num && video.routine?.round === this.round));
    }

    uploadedVideoOfRoutine(num) {
        return first(this.uploaded_videos.filter(video => video.num === num && video.round === this.round));
    }

    noVideo(num) {
        return !this.videoOfRoutine(num) && !this.uploadedVideoOfRoutine(num);
    }
}
