import Model from '@/Models/Model.js';
import Meet from '@/Models/Meet.js';

export default class Coupon extends Model {
    static entity = 'coupons';

    static modelName = 'Coupon';

    static fields() {
        return {
            id: this.attr(null),
            meet_id: this.attr(null),
            stripe_id: this.attr(null),
            name: this.attr(null),
            code: this.attr(null),
            amount_off: this.attr(null),
            percent_off: this.attr(null),
            duration: this.attr(null),
            redeem_by: this.attr(null),

            ...super.fields(),

            meet: this.belongsTo(Meet, 'meet_id'),
        };
    }
}
