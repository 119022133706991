import Model from '@/Models/Model.js';

export default class JobApplication extends Model {
    static entity = 'job_applications';

    static modelName = 'JobApplication';

    static fields() {
        return {
            id: this.attr(null),

            ...super.fields(),
        };
    }
}
