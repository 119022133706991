import Model from '@/Models/Model.js';

export default class FlightResultCategory extends Model {
    static entity = 'flight_result_category';

    static primaryKey = ['flight_id', 'result_category_id'];

    static fields() {
        return {
            flight_id: this.attr(null),
            result_category_id: this.attr(null),
        };
    }
}
