import BaseScore from '@/Models/BaseScore.js';
import DoubleMiniSkillDeductions from '@/Models/DoubleMiniSkillDeductions.js';

export default class DoubleMiniScore extends BaseScore {
    static entity = 'double-mini-scores';

    constructor(record) {
        super(record);

        this.nova_resource = 'double-mini-scores';
        this.className = 'App\\Models\\DoubleMiniScore';
    }

    static fields() {
        return {
            ...super.fields(),

            double_mini_skill_deductions: this.hasMany(DoubleMiniSkillDeductions, 'double_mini_score_id'),
        };
    }

    get skill_deductions() {
        return this.double_mini_skill_deductions;
    }
}
