import Model from '@/Models/Model.js';
import levels from '@/levels.json';
import { keyedLevels } from '@/levels.js';
import Result from '@/Models/Result.js';
import Meet from '@/Models/Meet.js';

export default class ResultCategory extends Model {
    static entity = 'result-categories';

    static fields() {
        return {
            id: this.attr(null),
            meet_id: this.attr(null),
            gender: this.string(null),
            event: this.attr(null),
            level: this.attr(null),
            age_group: this.attr(null),
            had_finals: this.boolean(false),
            min_age: this.number(null),
            max_age: this.number(null),
            athlete_count: this.number(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),

            meet: this.belongsTo(Meet, 'meet_id'),
            results: this.hasMany(Result, 'category_id'),
        };
    }

    get event_title() {
        const map = {
            TI: 'Trampoline',
            DM: 'Double Mini',
            TU: 'Tumbling',
            TS: 'Synchro',
        };

        return map[this.event];
    }

    get event_short_title() {
        const map = {
            TI: 'TRA',
            DM: 'DMT',
            TU: 'TUM',
            TS: 'SYN',
        };

        return map[this.event];
    }

    get is_elite() {
        return keyedLevels[this.level].isElite;
    }

    get level_title() {
        const foundLevel = _.find(levels, level => level.short === this.level);

        return foundLevel.label;
    }

    get level_short() {
        const foundLevel = _.find(levels, level => level.short === this.level);

        return foundLevel.short;
    }

    get event_results_key() {
        const map = {
            TI: 'trampoline_scores',
            DM: 'double_mini_scores',
            TU: 'tumbling_scores',
            TS: 'synchro_scores',
        };

        return map[this.event];
    }

    get skill_deductions_key() {
        const map = {
            TI: 'trampoline_scores.trampoline_skill_deductions',
            DM: 'double_mini_scores.double_mini_skill_deductions',
            TU: 'tumbling_scores.tumbling_skill_deductions',
            TS: 'synchro_scores.synchro_skill_deductions',
        };

        return map[this.event];
    }

    description({ without } = {}) {
        const description = [];

        if (!without || !without.includes('event')) {
            description.push(this.event_short_title);
        }

        if (!without || !without.includes('gender')) {
            description.push(this.gender.toUpperCase());
        }

        if (!without || !without.includes('level')) {
            description.push(this.level_title);
        }

        if ((!without || !without.includes('age_group')) && !this.is_elite) {
            description.push(this.age_group);
        }

        return description.join(' ');
    }

    static broadcastChannel(meetId) {
        return `meet-${meetId}`;
    }
}
