import 'vanilla-cookieconsent/dist/cookieconsent.css';
import * as CookieConsent from 'vanilla-cookieconsent';
import config from './cookieconsent-config.js';

const CookieConsentVue = {
    install(Vue) {
        CookieConsent.run(config);
        // eslint-disable-next-line no-param-reassign
        Vue.prototype.$CC = CookieConsent;
    },
};

export default CookieConsentVue;
