import Model from '@/Models/Model.js';
import Athlete from '@/Models/Athlete.js';
import Result from '@/Models/Result.js';
import User from '@/Models/User.js';

export default class Video extends Model {
    static entity = 'videos';

    static modelName = 'Video';

    static fields() {
        return {
            id: this.attr(null),
            uuid: this.attr(null),
            user_id: this.attr(null),
            athlete_id: this.attr(null),
            result_id: this.attr(null),
            routine_id: this.attr(null),
            start_list_id: this.attr(null),
            routine_type: this.attr(null),
            routine_type_entity: this.attr(null),
            coconut_id: this.attr(null),
            title: this.attr(null),
            description: this.attr(null),
            output: this.attr(null),
            prefer_hls: this.boolean(true),
            processed: this.attr(null),
            processing_failed_at: this.attr(null),
            video_filename: this.attr(null),
            cloud_file: this.attr(null),
            visibility: this.attr(null),
            allow_votes: this.attr(null),
            allow_comments: this.attr(null),

            ...super.fields(),

            user: this.belongsTo(User, 'user_id'),
            athlete: this.belongsTo(Athlete, 'athlete_id'),
            result: this.belongsTo(Result, 'result_id'),
            routine: this.morphTo('routine_id', 'routine_type_entity'),
        };
    }

    get thumbnailUrl() {
        const images = _.find(this.output, { key: 'jpg:small' });

        if (!images) {
            return '';
        }

        return images.urls[0];
    }

    get hls_url() {
        const httpsStream = _.find(this.output, { key: 'httpstream' });

        if (!httpsStream) {
            return '';
        }

        const hls = _.find(httpsStream.urls, { format: 'hls' });

        return hls.url;
    }

    wasUploadedBy(user) {
        if (!user) {
            return false;
        }

        return this.user_id === user.id || user.id === 1;
    }

    get event() {
        return this.result.event;
    }

    get event_display() {
        return this.result.event_display.toUpperCase();
    }
}
