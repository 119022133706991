import moment from 'moment-timezone';
import Model from '@/Models/Model.js';
import Meet from '@/Models/Meet.js';
import Session from '@/Models/Session.js';
import StartList from '@/Models/StartList.js';
import { eventFromProscore } from '@/helpers.js';
import ResultCategory from '@/Models/ResultCategory.js';
import FlightResultCategory from '@/Models/FlightResultCategory.js';

export default class Flight extends Model {
    static entity = 'flights';

    static fields() {
        return {
            id: this.attr(null),
            meet_id: this.attr(null),
            session_id: this.attr(null),
            title: this.attr(null),
            original_title: this.attr(null),
            equipment: this.attr(null),
            event: this.attr(null),
            round: this.attr(null),
            size: this.attr(null),
            starts_at: this.attr(null),
            ends_at: this.attr(null),
            order: this.attr(null),

            ...super.fields(),

            meet: this.belongsTo(Meet, 'meet_id'),
            session: this.belongsTo(Session, 'session_id'),
            start_lists: this.hasMany(StartList, 'flight_id'),
            result_categories: this.belongsToMany(
                ResultCategory,
                FlightResultCategory,
                'flight_id',
                'result_category_id',
            ),
        };
    }

    get event_key() {
        return eventFromProscore(this.event);
    }

    get leaderboard_label() {
        return `${this.event_key.toUpperCase()} ${this.title}`;
    }

    get title_with_time() {
        if (!this.meet) {
            return this.title;
        }

        return `${this.title} ${moment.utc(this.starts_at).tz(this.meet.timezone).format('ddd h:mm a')}`;
    }

    get title_with_event() {
        const event = eventFromProscore(this.event);
        return `${this.title} ${event.toUpperCase()}`;
    }

    get routines_completed() {
        return _.sum(
            this.start_lists.map(startList => {
                return startList.routines_completed;
            }),
        );
    }

    get routines_to_complete() {
        return _.sum(
            this.start_lists.map(startList => {
                return startList.routines_to_complete;
            }),
        );
    }

    get completed_percent() {
        if (this.routines_to_complete <= 0) {
            return 0;
        }

        return (this.routines_completed / this.routines_to_complete) * 100;
    }

    titleWithTime(meet, format = 'ddd h:mm a') {
        return `${this.title} - ${moment.utc(this.starts_at).tz(meet.timezone).format(format)}`;
    }
}
