import Vue from 'vue';
import numeral from 'numeral';

Vue.prototype.$score = (score, format = '0.0') => {
    return numeral(score).format(format);
};

Vue.prototype.$isIOS = window.iPhoneApp || false;

Vue.prototype.$isDevEnv = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'local';

Vue.prototype.openIOSLink = url => {
    if (!Vue.prototype.$isIOS) {
        window.location.href = url;

        return;
    }

    // window.webkit.messageHandlers.interceptor.postMessage({
    //     type: 'nativeFunction',
    //     data: 'test',
    // });

    window.webkit.messageHandlers.openExternalLink.postMessage(url);
};
