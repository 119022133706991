import Model from '@/Models/Model.js';

export default class AthleteFollow extends Model {
    static entity = 'athlete-follows';

    static fields() {
        return {
            id: this.attr(null),
            user_id: this.attr(null),
            athlete_id: this.attr(null),

            follow_tra: this.boolean(true),
            follow_dmt: this.boolean(true),
            follow_tum: this.boolean(true),
            follow_syn: this.boolean(true),

            email: this.boolean(true),
            push: this.boolean(true),
            sms: this.boolean(true),

            ...super.fields(),

            user: this.belongsTo('users', 'user_id'),
            athlete: this.belongsTo('athletes', 'athlete_id'),
        };
    }

    get name() {
        return this.athlete.name;
    }

    toPayload() {
        return {
            entity: 'athlete',
            user_id: this.user_id,
            athlete_id: this.athlete_id,
            follow_tra: this.follow_tra,
            follow_dmt: this.follow_dmt,
            follow_tum: this.follow_tum,
            follow_syn: this.follow_syn,

            email: this.email,
            push: this.push,
            sms: this.sms,
        };
    }
}
