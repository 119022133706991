import Model from '@/Models/Model.js';

export default class NotificationPreferences extends Model {
    static entity = 'notification_preferences';

    static modelName = 'NotificationPreferences';

    static fields() {
        return {
            id: this.attr(null),

            user_id: this.attr(null),
            email: this.boolean(true),
            push: this.boolean(false),
            sms: this.boolean(false),

            results: this.boolean(true),
            announcements: this.boolean(true),
            mobility: this.boolean(true),
            qualification: this.boolean(true),

            ...super.fields(),

            user: this.belongsTo('users', 'user_id'),
        };
    }
}
